// Module with functions to import elements from excel files
import * as XLSX from "xlsx"

// Global constants
import * as con from "../GlobalConstants"
import { formatDate, isStingValidForDate, parseDate } from "./dateFunctions";



// Function that parses the uploaded file from event 
export const parseUploadedFile = async (file) => {
    
    //const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const [correct_records, incorrect_records] = extractTransactionFromWorkbook(workbook)

    return([correct_records, incorrect_records])
}

  
// Function that process the excel workbook and creates the json arrays
export const extractTransactionFromWorkbook = (workbook) =>
{

    // Exposures
    let exposures = XLSX.utils.sheet_to_json(workbook.Sheets[con.EXPOSURES_SHEET_NAME], 
                                                {'header': con.EXPOSURES_COLUMN_ORDER, 
                                                 'range':1});
    
    exposures = exposures.map(readExposure)

    // Forward coverages
    let forward_coverages = XLSX.utils.sheet_to_json(workbook.Sheets[con.COVERAGES_FWD_SHEET_NAME], 
                                                {'header': con.COVERAGES_FWD_COLUMN_ORDER, 
                                                'range':1});

    forward_coverages = forward_coverages.map(readCoverageForward)

    // Options coverages
    let options_coverages = XLSX.utils.sheet_to_json(workbook.Sheets[con.COVERAGES_OPTIONS_SHEET_NAME], 
                                                    {'header': con.COVERAGES_OPTIONS_COLUMN_ORDER, 
                                                    'range':1});

    options_coverages = options_coverages.map(readCoverageOptions)

    // SPOT coverages
    let spot_coverages = null
    if(con.COVERAGES_SPOT_SHEET_NAME_IMPORTER in workbook.Sheets)
    {
      spot_coverages = XLSX.utils.sheet_to_json(workbook.Sheets[con.COVERAGES_SPOT_SHEET_NAME_IMPORTER], 
                                                {'header': con.COVERAGES_SPOT_COLUMN_ORDER, 
                                                'range':1});
    }
    else
    {
      spot_coverages = XLSX.utils.sheet_to_json(workbook.Sheets[con.COVERAGES_SPOT_SHEET_NAME_EXPORTER], 
        {'header': con.COVERAGES_SPOT_COLUMN_ORDER, 
        'range':1});

    }
    

    spot_coverages = spot_coverages.map(readCoverageSpot)
    
    // Accounts
    let accounts = XLSX.utils.sheet_to_json(workbook.Sheets[con.ACCOUNTS_SHEET_NAME], 
                                                    {'header': con.ACCOUNTS_COLUMN_ORDER, 
                                                    'range':1});


    accounts = accounts.map(readAccount)

    // Filters errors
    // Correct
    let correct_exposures = exposures.filter(ob => ob[con.PARSED_RECORD_STATUS] === con.OK)
    let correct_forward_coverages = forward_coverages.filter(ob => ob[con.PARSED_RECORD_STATUS] === con.OK)
    let correct_options_coverages = options_coverages.filter(ob => ob[con.PARSED_RECORD_STATUS] === con.OK)
    let correct_spot_coverages = spot_coverages.filter(ob => ob[con.PARSED_RECORD_STATUS] === con.OK)
    let correct_accounts = accounts.filter(ob => ob[con.PARSED_RECORD_STATUS] === con.OK)
    // Incorrect
    let incorrect_exposures = exposures.filter(ob => ob[con.PARSED_RECORD_STATUS] === con.ERROR)
    let incorrect_forward_coverages = forward_coverages.filter(ob => ob[con.PARSED_RECORD_STATUS] === con.ERROR)
    let incorrect_options_coverages = options_coverages.filter(ob => ob[con.PARSED_RECORD_STATUS] === con.ERROR)
    let incorrect_spot_coverages = spot_coverages.filter(ob => ob[con.PARSED_RECORD_STATUS] === con.ERROR)
    let incorrect_accounts = accounts.filter(ob => ob[con.PARSED_RECORD_STATUS] === con.ERROR)


    return([ { [con.EXPOSURES] : correct_exposures, 
              [con.COVERAGES_FWD] : correct_forward_coverages, 
              [con.COVERAGES_OPTION] : correct_options_coverages, 
              [con.COVERAGES_SPOT] : correct_spot_coverages, 
              [con.ACCOUNTS] : correct_accounts},

              { [con.EXPOSURES] : incorrect_exposures, 
                [con.COVERAGES_FWD] : incorrect_forward_coverages, 
                [con.COVERAGES_OPTION] : incorrect_options_coverages, 
                [con.COVERAGES_SPOT] : incorrect_spot_coverages, 
                [con.ACCOUNTS] : incorrect_accounts},
            ])
}

export const cropComments = (trans) => {


  let crop = false
  Object.keys(trans).forEach((k =>{

    trans[k].forEach((tr) => {
      if(tr[con.COMMENT].length > con.MAX_COMMENT_LENGTH)
      {
        crop = true
        tr[con.COMMENT] = tr[con.COMMENT].substring(0, con.MAX_COMMENT_LENGTH) 

      }
    })

  }))

  return(crop)

}

export const readExposure = (ob) => {

    let exp = {}
    exp[con.ROW_NUMBER] = ob['__rowNum__']
    exp[con.ID] = ob['__rowNum__'] - 1

    // Errors
    exp[con.PARSED_ERROR_CODES] = []

    // Date
    let expirationDate = ob[con.EXPIRATION_DATE]
    if(expirationDate === null || expirationDate === undefined)
    {
      exp[con.PARSED_RECORD_STATUS] = con.ERROR
      exp[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_MISSING_DATE)      
    }
    else if(!isStingValidForDate(XLSX.SSF.format(con.DATE_FORMAT,expirationDate)))
    {
      exp[con.PARSED_RECORD_STATUS] = con.ERROR
      exp[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_DATE_WRONG_FORMAT)      
    }
    else    
      expirationDate = formatDate(parseDate(XLSX.SSF.format(con.DATE_FORMAT,expirationDate)))
    

    // Amount
    if(ob[con.AMOUNT] === null || ob[con.AMOUNT] === undefined)
    {
      exp[con.PARSED_RECORD_STATUS] = con.ERROR
      exp[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_MISSING_AMOUNT)      
    } else if (isNaN(ob[con.AMOUNT])){
      exp[con.PARSED_RECORD_STATUS] = con.ERROR
      exp[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_AMOUNT_WRONG_FORMAT)  
    }

    // Check for errors
    if( exp[con.PARSED_RECORD_STATUS] === con.ERROR)
      return(exp)

  // Finished Error Checking
  // ---------------------------------

    exp[con.EXPIRATION_DATE] = expirationDate
    exp[con.AMOUNT] = ob[con.AMOUNT]
    exp[con.OPENING_TRM] = ob[con.OPENING_TRM] === null || ob[con.OPENING_TRM] === undefined ? 0 : Number(ob[con.OPENING_TRM])
    exp[con.COMMENT] = ob[con.COMMENT] === null || ob[con.COMMENT] === undefined ? "" : ob[con.COMMENT]
    exp[con.STATE] = con.ACTIVE

    exp[con.PARSED_RECORD_STATUS] = con.OK
    
    return(exp)
}

export const readCoverageForward= (ob) => {

  let cov = {}
  cov[con.ROW_NUMBER] = ob['__rowNum__']
  cov[con.ID] = ob['__rowNum__'] - 1

  // Errors
  cov[con.PARSED_ERROR_CODES] = []

  // Date
  let expirationDate = ob[con.EXPIRATION_DATE]
  if(expirationDate === null || expirationDate === undefined)
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_MISSING_DATE)      
  }
  else if(!isStingValidForDate(XLSX.SSF.format(con.DATE_FORMAT,expirationDate)))
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_DATE_WRONG_FORMAT)      
  }
  else    
    expirationDate = formatDate(parseDate(XLSX.SSF.format(con.DATE_FORMAT,expirationDate)))

  let openingDate = ob[con.OPENING_DATE]
  if(openingDate !== null && openingDate !== undefined && !isStingValidForDate( XLSX.SSF.format(con.DATE_FORMAT,openingDate)))
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_DATE_WRONG_FORMAT)      
  }
  else
    openingDate = formatDate(parseDate( XLSX.SSF.format(con.DATE_FORMAT,openingDate)))

  // Amount
  if(ob[con.AMOUNT] === null || ob[con.AMOUNT] === undefined)
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_MISSING_AMOUNT)      
  } else if (isNaN(ob[con.AMOUNT])){
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_AMOUNT_WRONG_FORMAT)  
  }
  
  // OPENING SPOT
  if(ob[con.OPENING_SPOT] === null || ob[con.OPENING_SPOT] === undefined)
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_MISSING_OPENING_SPOT)      
  } else if (isNaN(ob[con.AMOUNT])){
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_OPENING_SPOT_WRONG_FORMAT)  
  }
  // Rate
  if(ob[con.RATE] === null || ob[con.RATE] === undefined)
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_MISSING_RATE)      
  } else if (isNaN(ob[con.AMOUNT])){
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_RATE_WRONG_FORMAT)  
  }

  // Check for errors
  if( cov[con.PARSED_RECORD_STATUS] === con.ERROR)
    return(cov)

  // Finished Error Checking
  // ---------------------------------


  cov[con.EXPIRATION_DATE] = expirationDate
  cov[con.OPENING_DATE] = openingDate
  cov[con.AMOUNT] = ob[con.AMOUNT]
  cov[con.OPENING_SPOT] = ob[con.OPENING_SPOT]
  cov[con.COMMENT] = ob[con.COMMENT] === null || ob[con.COMMENT] === undefined ? "" : ob[con.COMMENT]
  cov[con.RATE] = ob[con.RATE]
  cov[con.STATE] = con.ACTIVE
  cov[con.COVERAGE_TYPE] = ob[con.COVERAGE_TYPE] === null || ob[con.COVERAGE_TYPE] === undefined || ob[con.COVERAGE_TYPE].toUpperCase() === "COMPRA" ? con.BUY : con.SELL
  mapCounterpary(ob, cov)
  cov[con.PARSED_RECORD_STATUS] = con.OK

  return(cov)
}

export const readCoverageOptions= (ob) => {

  let cov = {}
  cov[con.ROW_NUMBER] = ob['__rowNum__']
  cov[con.ID] = ob['__rowNum__'] - 1

  // Errors
  cov[con.PARSED_ERROR_CODES] = []

  // Date
  let expirationDate = ob[con.EXPIRATION_DATE]
  if(expirationDate === null || expirationDate === undefined)
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_MISSING_DATE)      
  }
  else if(!isStingValidForDate(XLSX.SSF.format(con.DATE_FORMAT,expirationDate)))
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_DATE_WRONG_FORMAT)      
  }
  else    
    expirationDate = formatDate(parseDate(XLSX.SSF.format(con.DATE_FORMAT,expirationDate)))

  let openingDate = ob[con.OPENING_DATE]
  if(openingDate !== null && openingDate !== undefined && !isStingValidForDate( XLSX.SSF.format(con.DATE_FORMAT,openingDate)))
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_DATE_WRONG_FORMAT)      
  }
  else
    openingDate = formatDate(parseDate( XLSX.SSF.format(con.DATE_FORMAT,openingDate)))

  // Amount
  if(ob[con.AMOUNT] === null || ob[con.AMOUNT] === undefined)
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_MISSING_AMOUNT)      
  } else if (isNaN(ob[con.AMOUNT])){
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_AMOUNT_WRONG_FORMAT)  
  }

  // Strike Price
  if(ob[con.STRIKE] === null || ob[con.STRIKE] === undefined)
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_MISSING_RATE)      
  } else if (isNaN(ob[con.AMOUNT])){
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_STRIKE_WRONG_FORMAT)  
  }

  // Strike Price
  if(ob[con.PREMIUM] === null || ob[con.PREMIUM] === undefined)
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_MISSING_RATE)      
  } else if (isNaN(ob[con.AMOUNT])){
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_PREMIUM_WRONG_FORMAT)  
  }

  // Check for errors
  if( cov[con.PARSED_RECORD_STATUS] === con.ERROR)
    return(cov)

  // Finished Error Checking
  // ---------------------------------
  cov[con.EXPIRATION_DATE] = expirationDate
  cov[con.OPENING_DATE] = openingDate
  cov[con.AMOUNT] = ob[con.AMOUNT]
  cov[con.COMMENT] = ob[con.COMMENT] === null || ob[con.COMMENT] === undefined ? "" : ob[con.COMMENT]
  cov[con.STRIKE] = ob[con.STRIKE]
  cov[con.PREMIUM] = ob[con.PREMIUM]
  cov[con.OPENING_SPOT] = ob[con.OPENING_SPOT]
  cov[con.STATE] = con.ACTIVE
  cov[con.COVERAGE_TYPE] = ob[con.COVERAGE_TYPE] === null || ob[con.COVERAGE_TYPE] === undefined || ob[con.COVERAGE_TYPE].toUpperCase() === "COMPRA" ? con.BUY : con.SELL
  mapCounterpary(ob, cov)
  cov[con.OPTION_TYPE] = ob[con.OPTION_TYPE] === null || ob[con.OPTION_TYPE] === undefined || ob[con.OPTION_TYPE].toUpperCase() === "CALL" ? con.CALL : con.PUT
  cov[con.PARSED_RECORD_STATUS] = con.OK
  return(cov)
}


export const readCoverageSpot= (ob) => {

  let cov = {}
  cov[con.ROW_NUMBER] = ob['__rowNum__']
  cov[con.ID] = ob['__rowNum__'] - 1

  // Error Check
  cov[con.PARSED_ERROR_CODES] = []

  // Date
  let openingDate = ob[con.OPENING_DATE]
  if(openingDate !== null && openingDate !== undefined && !isStingValidForDate( XLSX.SSF.format(con.DATE_FORMAT,openingDate)))
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_DATE_WRONG_FORMAT)      
  }
  else
    openingDate = formatDate(parseDate( XLSX.SSF.format(con.DATE_FORMAT,openingDate)))


  // Amount
  if(ob[con.AMOUNT] === null || ob[con.AMOUNT] === undefined)
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_MISSING_AMOUNT)      
  }  else if (isNaN(ob[con.AMOUNT])){
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_AMOUNT_WRONG_FORMAT)  
  }
  // Rate
  if(ob[con.RATE] === null || ob[con.RATE] === undefined)
  {
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_MISSING_RATE)      
  }  else if (isNaN(ob[con.AMOUNT])){
    cov[con.PARSED_RECORD_STATUS] = con.ERROR
    cov[con.PARSED_ERROR_CODES].push(con.PARSING_ERROR_CODE_RATE_WRONG_FORMAT)  
  }

  // Check for errors
  if( cov[con.PARSED_RECORD_STATUS] === con.ERROR)
    return(cov)

  // Finished Error Checking
  // ---------------------------------

  cov[con.OPENING_DATE] = openingDate
  cov[con.AMOUNT] = ob[con.AMOUNT]
  cov[con.COMMENT] =ob[con.COMMENT] === null || ob[con.COMMENT] === undefined ? "" : ob[con.COMMENT]
  cov[con.RATE] = ob[con.RATE]
  cov[con.SPOT_COVERAGE_TYPE] = con.CASH_REGISTER

  cov[con.PARSED_RECORD_STATUS] = con.OK

  return(cov)
}

export const readAccount= (ob) => {

  let acc = {}
  acc[con.ROW_NUMBER] = ob['__rowNum__']
  acc[con.ID] = ob['__rowNum__'] - 1

  // Error Check
  acc[con.PARSED_ERROR_CODES] = []

  // Amount
  // Corrects
  if(ob[con.AMOUNT] === null || ob[con.AMOUNT] === undefined)
    ob[con.AMOUNT] = 0

  // Check for errors
  if( acc[con.PARSED_RECORD_STATUS] === con.ERROR)
    return(acc)

  // Finished Error Checking
  // ---------------------------------

  acc[con.AMOUNT] = ob[con.AMOUNT]
  acc[con.COMMENT] = ob[con.COMMENT] === null || ob[con.COMMENT] === undefined ? "" : ob[con.COMMENT]
  acc[con.ACCOUNT_TYPE] = ob[con.ACCOUNT_TYPE] === "Cuenta de Compensación" ? con.COMPENSATION_ACCOUNT : con.NATURAL_COVERAGE

  acc[con.PARSED_RECORD_STATUS] = con.OK


  return(acc)
}

const mapCounterpary = (ob, cov) => {
  switch(true) {

    case ob[con.COUNTERPARTY] === "Bancolombia":
      cov[con.COUNTERPARTY] = con.BANCOLOMBIA
      break;

    case ob[con.COUNTERPARTY] === "Davivienda":
      cov[con.COUNTERPARTY] = con.DAVIVIENDA
      break;

    case ob[con.COUNTERPARTY] === "B. Bogota":
      cov[con.COUNTERPARTY] = con.BOGOTA
      break;

    case ob[con.COUNTERPARTY] === "Scotiabank":
      cov[con.COUNTERPARTY] = con.SCOTIABANK
      break;

    case ob[con.COUNTERPARTY] === "Itau":
      cov[con.COUNTERPARTY] = con.ITAU
      break;

    case ob[con.COUNTERPARTY] === "Corficolombiana":
      cov[con.COUNTERPARTY] = con.CORFICOLOMBIANA
      break;

    case ob[con.COUNTERPARTY] === "B. Occidente":
      cov[con.COUNTERPARTY] = con.OCCIDENTE 
      break;

    case ob[con.COUNTERPARTY] === "BBVA":
      cov[con.COUNTERPARTY] = con.BBVA
      break;

    case ob[con.COUNTERPARTY] === "B. Santander":
      cov[con.COUNTERPARTY] = con.SANTANDER
      break;

    default:
      cov[con.COUNTERPARTY] = undefined
  }
}