// Transaction Summary Hook
// TODO - Documentar
import {useEffect, useState } from 'react'

import * as con from "../../GlobalConstants"
import { processTransactionsWithSummary } from '../../utils/transactionFunctions'
import { RefApplicationParameterHook, RefModelApplicationParameterHook, RefSPOT } from './ApplicationParametersHooks'
import { AllModelParameterHook } from './ModelParameterHooks'
import { ScenariosHook } from './ScenariosHook'
import { AccountsHook, ExposuresHook, ForwardCoveragesHook } from './TransactionsHooks'

// Hook for managing a single transaction summary
export const AllScenariosTransactionsSummaryHook = (selectedScenarios = null) => {

// Transactions and parameters
  // -----------------  
  const exposures = ExposuresHook()
  const fwdCoverages = ForwardCoveragesHook()
  const accounts = AccountsHook()
  const modelParameters = AllModelParameterHook()

  const spot = RefSPOT()
  const currentDate = RefApplicationParameterHook(con.CURRENT_DATE)

  // Scenarios
  const scenarios = ScenariosHook()

  // Model Application Parameters
  const modelApplicationParameters = RefModelApplicationParameterHook()
  
  // Hooks variables
  const [computing, setComputing] = useState(() => true)
  const [allSummaries, setAllSummaries] = useState(() => {return({})})
  const [hasSummary, setHasSummary] = useState(() => true)

                          
    // Transactions or model parameters change
    useEffect(() => {
      
      // Computing
      setComputing(true)
      setAllSummaries({})
      setHasSummary(true)

      let allSummariesTemp = {}

      let completed = 0
      let total_scenarios = (selectedScenarios != null) ? selectedScenarios.length : Object.keys(scenarios).length

      Object.keys(scenarios).filter((k) => selectedScenarios == null || selectedScenarios.includes(k))
                            .forEach(sce => {
        
        
        new Promise(function (success, error) {
            
          setTimeout(async () => {
            
            // Function for updating the summary (async to not block excecution)
            let [transSummary, summaryRow] = await processTransactionsWithSummary(
                                                                        currentDate,
                                                                        spot,
                                                                        scenarios[sce].gen_fun,
                                                                        modelParameters, 
                                                                        exposures,
                                                                        fwdCoverages,
                                                                        accounts,
                                                                        modelApplicationParameters)
            success([transSummary, summaryRow])
          
          
          }, con.UI_TIMEOUT);
          
        }).then(([transSummary, summaryRow]) => {
                      
            
          if(Object.keys(transSummary).length > 0)
          {
            allSummariesTemp[sce] = summaryRow
            setHasSummary(true)
          }
          else
            setHasSummary(false)

          completed += 1
          if(completed === total_scenarios)
          {
            setComputing(false)
            setAllSummaries(allSummariesTemp)
          }
                      
        })

      })
            

    }, [exposures, fwdCoverages, accounts, modelParameters, scenarios, selectedScenarios, currentDate, spot, modelApplicationParameters])


    return([allSummaries, computing, hasSummary])

}


