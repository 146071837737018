// Different functions for interacting wih market

// Global constants
import * as con from "../GlobalConstants"
import { getModelParameter } from "../store/actions/modelParameters"
import { parseDate } from "./dateFunctions"
import axios from 'axios';
import { setApplicationParameter } from "../store/actions/applicationParameters";
import { getMarketValue } from "../store/actions/market";
import { binaryIndexSearch } from "../GlobalFunctions";
import { handleRequestError } from "./errorFunctions";


// Functions that updates SPOT
export const updateSpot = () =>
{
    let current_spot = getModelParameter(con.SPOT)

    // Does the request
    axios.get(con.rest_base_url + con.spot_delay_url)
        .then(res => {
            if(res.data[con.STATUS] === con.OK)
            {
                if (current_spot !== res.data[con.SPOT])
                {
                    console.log('Spot Changed')
                    setApplicationParameter(con.SPOT_DIRECTION, (current_spot < res.data[con.SPOT]) ? con.UP : con.DOWN)
                    setApplicationParameter(con.SPOT, Number(res.data[con.SPOT].toFixed(2))) 
                    console.log(res.data["source"])
                }
                
                console.log('Spot Updated')
                setApplicationParameter(con.LAST_SPOT_UPDATE, res.data[con.DATES].slice(-8)) // Date comes in ISO format
            }              
            else
                console.log("Error retrieving SPOT")
            
        })
        .catch(err => console.log(err))
    
}


export const getClosestTRM = (date) =>
{
    // Checks if is string
    if (typeof date === 'string' || date instanceof String)
        date = parseDate(date)

    // Gets the historic Data
    let allHistoricPrices = getMarketValue(con.HISTORIC_DOLLAR_INFO)
    
    // Gets Index
    let index = binaryIndexSearch(date,
        allHistoricPrices[con.DATES],
        false)

    return(allHistoricPrices[con.TRM][index])
}


// Quotes the selected assets from the server
export const quoteAssets = async (assetArray) =>
{        
    
    let url = con.rest_base_url + con.quote_assets_url

     // Declares the headers
     const config = { "Content-Type":"application/json"}

    try 
    {
        const res = await axios.post(url, {[con.CODES] : assetArray}, config)

        if(res.data[con.STATUS] === con.OK)
            return res.data[con.PRICES]
        else    
            return null

    } catch (err) { 
        handleRequestError(err)
        return null 
    }   
    
    
}

