// TODO - Documentar
// Transaction Summary Hook
import {useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import * as con from "../../GlobalConstants"
import { processTransactionsWithSummary } from '../../utils/transactionFunctions'
import { RefApplicationParameterHook, RefModelApplicationParameterHook, RefSPOT } from './ApplicationParametersHooks'
import { AllModelParameterHook } from './ModelParameterHooks'
import { AccountsHook, ExposuresHook, ForwardCoveragesHook } from './TransactionsHooks'



// Hook for managing a single transaction summary with summary
export const RefTransactionsSummaryTableHook = ({forcedVisualizationPeriodicity = null, onlyTable = false, onlySummary = false} = { onlyTable : false, onlySummary : false}) => {


  // Transactions
  // ----------------- 
  const exposures = ExposuresHook()
  const fwdCoverages = ForwardCoveragesHook()
  const accounts = AccountsHook()

  // Scenarios
  // ----------------
  const selectedScenarioID = RefApplicationParameterHook(con.SELECTED_SCENARIO)
  const scenarios = useSelector((state) => state[con.STORE][con.REDUCER_SCENARIOS])

  // Parameters
  // ----------------- 
  const spot = RefSPOT()
  const currentDate = RefApplicationParameterHook(con.CURRENT_DATE)
  const modelParameters = AllModelParameterHook()
  const modelApplicationParameters = RefModelApplicationParameterHook()
  const periodicityVisualization = RefApplicationParameterHook(con.VISUALIZATION_PERIODICITY)
  const [localVisualizationPeriodicity, setLocalVisualizationPeriodicity] = useState(() => forcedVisualizationPeriodicity!= null ? forcedVisualizationPeriodicity:periodicityVisualization)

  // Loading Status for the transactions
  const allTransactionLoaded = RefApplicationParameterHook(con.ALL_TRANSACTIONS_LOADED)

  // Main Object
  const [transactionSummaryObject, setTransactionSummaryObject] = useState(() => [])

  // Summary Row
  const [summaryRow, setSummaryRow] = useState(() => {})
  
  // Computing Variable
  const [computing, setComputing] = useState(() => true)

  // Has summary table
  const [hasSummaryTable, setHasSummaryTable] = useState(() => false)


  // Computing method
  const computeTransactionSummary = useCallback(
    () => {      

      // Only checks if all transactions have been loaded
      if(allTransactionLoaded)
      {                 
          new Promise(function (success) {
              
            setTimeout(async () => {
              
              // Function for updating the summary (async to not block execution)
              let [df_trans, sumRow] = await processTransactionsWithSummary(currentDate,
                                                        spot,
                                                        scenarios[selectedScenarioID].gen_fun,
                                                        modelParameters, 
                                                        exposures,
                                                        fwdCoverages,
                                                        accounts,
                                                        {...modelApplicationParameters, [con.VISUALIZATION_PERIODICITY] : localVisualizationPeriodicity})
              success([df_trans, sumRow])
            
            
            }, con.UI_TIMEOUT);
            
          }).then(([transSumm, sumRow]) => {

                                    
            setTransactionSummaryObject(transSumm)
            setSummaryRow(sumRow)
            setHasSummaryTable(transSumm.length > 0)
            setComputing(false)

                                  
          })
    }
    },
    [allTransactionLoaded, exposures, fwdCoverages, accounts, modelParameters, selectedScenarioID, localVisualizationPeriodicity, scenarios, currentDate, spot, modelApplicationParameters],
  )
                        
    // Reloads summary object on change
    useEffect(() => {
        
        // Computing
        setComputing(true)
        
        setTimeout(() => {   
          // Computes Transaction           
          computeTransactionSummary()
        }, con.UI_TIMEOUT);            

    }, [computeTransactionSummary])

    // Visualization
    useEffect(() => {
      
        if(forcedVisualizationPeriodicity == null)
           setLocalVisualizationPeriodicity(periodicityVisualization)

    }, [periodicityVisualization, forcedVisualizationPeriodicity])
    
    if(onlyTable)
      return([transactionSummaryObject, computing, hasSummaryTable])

    if(onlySummary)
      return([summaryRow, computing, hasSummaryTable])

    return([transactionSummaryObject, summaryRow, computing, hasSummaryTable])
}

