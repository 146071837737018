// TODO - Documentar
// Hooks for the diffetent transactions
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'


import * as con from "../../GlobalConstants"
import { arrayToObjectByAttribute, binaryIndexSearch, daysIdToInt, filterObject, size } from '../../GlobalFunctions'
import { formatDate, parseDate } from '../../utils/dateFunctions'
import { computeForwardAssessmentColumns, computeForwardAssessmentSummary, getGeneratorDevaluationFunctionByDay } from '../../utils/forwardFunctions'
import { allTransactionsCol } from '../../utils/transactionFunctions'
import { RefApplicationParameterHook } from './ApplicationParametersHooks'
import { RefMarketValueHook } from './MarketValuesHook'
import { computeOptionAssessmentColumns, computeOptionAssessmentSummary } from '../../utils/optionFunctions'
import { fetchVolatilitySurfaceByDate } from '../../store/actions/market'

export const ExposuresHook = () =>
{
    const trans = useSelector((state) => state[con.STORE][con.REDUCER_EXPOSURES])
    return(trans)
}

export const ForwardCoveragesHook = () =>
{
    const trans = useSelector((state) => state[con.STORE][con.REDUCER_FWD_COVERAGES])
    return(trans)
}


export const SpotCoveragesHook = () =>
{
    const trans = useSelector((state) => state[con.STORE][con.REDUCER_SPOT_COVERAGES])
    return(trans)
}

export const OptionCoveragesHook = () =>
{
    const trans = useSelector((state) => state[con.STORE][con.REDUCER_OPTION_COVERAGES])
    return(trans)
}

export const AccountsHook = () =>
{
    const trans = useSelector((state) => state[con.STORE][con.REDUCER_ACCOUNTS])
    return(trans)
}


export const TransactionColumnsHook = (selectedColumns, filterByBands) =>
{

    const selectedBand = RefApplicationParameterHook(con.SELECTED_BAND)

    const [usedColumns, setUsedColumns] = useState( () => selectedColumns.filter(col => !col[con.ID].includes("BAND") ||
                                                                                         !filterByBands ||
                                                                                         col[con.ID].includes(selectedBand))
                                                                          .map(col => {return({...allTransactionsCol[col[con.ID]], ...col})}))
    useEffect(() => {

        setUsedColumns(selectedColumns.filter(col => !col[con.ID].includes("BAND") ||
                                                !filterByBands ||
                                                col[con.ID].includes(selectedBand))
                                        .map(col => {return({...allTransactionsCol[col[con.ID]], ...col})}))

    }, [selectedColumns, filterByBands, selectedBand])


    return(usedColumns)
}


export const HasActivePurchases = (startDate, endDate, operation, clientType) =>
{
    const fwdCoverages = ForwardCoveragesHook()
    const spotCoverages = SpotCoveragesHook()
    const optionCoverages = OptionCoveragesHook()

    // Has purchases
    const [hasCoverages, setHasCoverages] = useState(() => false)

    useEffect(() => {

        let startDateOb = parseDate(startDate)
        let endDateOb = parseDate(endDate)
        let avSpot = {}

        if((clientType === con.IMPORTER && operation === con.BUY) || (clientType === con.EXPORTER && operation === con.BUY)){
            avSpot = filterObject(spotCoverages, (cov) => parseDate(cov[con.OPENING_DATE]) >= startDateOb && parseDate(cov[con.OPENING_DATE]) <= endDateOb)
        }

        let avFwd = filterObject(fwdCoverages, (cov) => parseDate(cov[con.OPENING_DATE]) >= startDateOb && parseDate(cov[con.OPENING_DATE]) <= endDateOb && cov[con.COVERAGE_TYPE] === operation)
        let avOption = filterObject(optionCoverages, (cov) => parseDate(cov[con.OPENING_DATE]) >= startDateOb && parseDate(cov[con.OPENING_DATE]) <= endDateOb && cov[con.COVERAGE_TYPE] === operation)

        setHasCoverages(size(avFwd) > 0 || size(avSpot) > 0 || size(avOption) > 0)

    }, [fwdCoverages, spotCoverages, optionCoverages, startDate, endDate, operation, clientType])

    return(hasCoverages)

}


export const AssessedForwardCoveragesHook = () =>
{

    // Forwards
  const forwards = ForwardCoveragesHook()

  // Rate
  const currentForwardRate = RefApplicationParameterHook(con.FORWARD_ASSESSMENT_RATE)

  // Forward Curve
  const all_fwd_curves = RefMarketValueHook(con.ALL_FORWARD_CURVES)

  // IBR
  const all_IBR = RefMarketValueHook(con.ALL_IBR)

  // Reference Date
  const referenceDate = RefApplicationParameterHook(con.FORWARD_ASSESSMENT_REFERENCE_DATE)

  // FWD filter Function
  const fwd_assessment_filter_fun = RefApplicationParameterHook(con.FWD_ASSESSMENT_FILTER_FUN)

  // GET CURRENT FORWARD CURVE GIVEN A SPECIFIC REFERENCE DATE
  const [currentFWD, setCurrentFWD ] = useState(() => con.FORWARD_CURVE_DAYS.map(_ => 0.08))

  useEffect(() => {
    const index = binaryIndexSearch(referenceDate,
        all_fwd_curves[con.DATES],
        false)

    if(index !== -1 && all_fwd_curves[con.VALUES].length > 0) {
        setCurrentFWD(con.FORWARD_CURVE_DAYS.map((col) => all_fwd_curves[con.VALUES][index][col]))
    }
}, [all_fwd_curves, referenceDate]);

  // GET CURRENT IBR CURVE GIVEN A SPECIFIC REFERENCE DATE
  const [currentIBR, setCurrentIBR ] = useState(() => con.IBR_DAYS.map(_ => 0.08))

  useEffect(() => {
    const index = binaryIndexSearch(referenceDate,
        all_IBR[con.DATES],
        false)

    if(index !== -1 && all_IBR[con.VALUES].length > 0) {
        setCurrentIBR(con.IBR_DAYS.map((col) => all_IBR[con.VALUES][index][col]))
    }
}, [all_IBR, referenceDate]);


  // Devaluation Function
  const [devaluationFunction, setDevaluationFunction] = useState(() => getGeneratorDevaluationFunctionByDay(con.FORWARD_CURVE_DAYS.map(daysIdToInt), currentFWD))

  useEffect(() => {
    setDevaluationFunction(() => getGeneratorDevaluationFunctionByDay(con.FORWARD_CURVE_DAYS.map(daysIdToInt), currentFWD))
  }, [currentFWD])


  // IBR Function
  const [ibrFunction, setIbrFunction] = useState(() => getGeneratorDevaluationFunctionByDay(con.IBR_DAYS.map(daysIdToInt), currentIBR))

  useEffect(() => {
    setIbrFunction(() => getGeneratorDevaluationFunctionByDay(con.IBR_DAYS.map(daysIdToInt), currentIBR))
  }, [currentIBR])


  // Assessed ForwardCoverages
  const [assessedFwdCoverages, setAssessedFwdCoverages] = useState(() => arrayToObjectByAttribute(Object.values(forwards).map((fw) => computeForwardAssessmentColumns(fw, referenceDate, currentForwardRate, devaluationFunction, ibrFunction))))

  // Assessment Summary
  const [assessmentSummary, setAssessmentSummary] = useState(() => {return({[con.ACTIVE_FWD_AMOUNT] : 0,
                                                                            [con.AVERAGE_FWD_RATE] : 0,
                                                                            [con.ASSESSMENT_RATE] : 0,
                                                                            [con.PORTFOLIO_ASSESSMENT] : 0})})

  // Updates Assessed Coverages
  useEffect(() => {

    let assessedArray = Object.values(forwards).map((fw) => computeForwardAssessmentColumns(fw, referenceDate, currentForwardRate, devaluationFunction, ibrFunction))

    // Sets Object
    setAssessedFwdCoverages(arrayToObjectByAttribute(assessedArray))

    // Sets Summary
    setAssessmentSummary(computeForwardAssessmentSummary(assessedArray, fwd_assessment_filter_fun, currentForwardRate))

  }, [forwards, referenceDate, currentForwardRate, devaluationFunction, ibrFunction, fwd_assessment_filter_fun])
  

  return([assessedFwdCoverages, assessmentSummary])

}

export const AssessedOptionCoveragesHook = () => {
    
    // Options
    const options = OptionCoveragesHook()

    // Historical Volatility
    const hist_volatility =  RefMarketValueHook(con.MARKET_SUMMARIES)[con.MARKET_TOTAL_DAYS][con.VOLATILITY]

    // USDCOP Rate to valuing options 
    const currentOptionRate = RefApplicationParameterHook(con.OPTION_ASSESSMENT_RATE)

    // SOFR Curve
    const all_sofr_curves = RefMarketValueHook(con.ALL_SOFR_CURVES)

    // IBR 
    const all_IBR = RefMarketValueHook(con.ALL_IBR)

    // Volatility Surface
    const volatilitySurface = RefMarketValueHook(con.VOLATILITY_SURFACE)

    // Reference Date
    const referenceDate = RefApplicationParameterHook(con.OPTION_ASSESSMENT_REFERENCE_DATE)

    // Option Filter Function
    const option_assessment_filter_function = RefApplicationParameterHook(con.OPTION_ASSESSMENT_FILTER_FUN)
    
    useEffect(() => {
        fetchVolatilitySurfaceByDate("2024-04-23")   // TODO Cambiar cuando la DB se alimente
        formatDate(referenceDate)
    }, [referenceDate])


    // GET CURRENT SOFR CURVE GIVEN A SPECIFIC REFERENCE DATE
    const [currentSofr, setCurrentSofr ] = useState(() => con.SOFR_DAYS.map(_ => 0.08))

    useEffect(() => {
    const index = binaryIndexSearch(referenceDate,
        all_sofr_curves[con.DATES],
        false)

    if(index !== -1 && all_sofr_curves[con.VALUES].length > 0) {
        setCurrentSofr(con.SOFR_DAYS.map((col) => all_sofr_curves[con.VALUES][index][col]))
    }
    }, [all_sofr_curves, referenceDate]);

    // GET CURRENT IBR CURVE GIVEN A SPECIFIC REFERENCE DATE
    const [currentIBR, setCurrentIBR ] = useState(() => con.IBR_DAYS.map(_ => 0.08))

    useEffect(() => {
        const index = binaryIndexSearch(referenceDate,
            all_IBR[con.DATES],
            false)

        if(index !== -1 && all_IBR[con.VALUES].length > 0) {
            setCurrentIBR(con.IBR_DAYS.map((col) => all_IBR[con.VALUES][index][col]))
        }
    }, [all_IBR, referenceDate]);

    // SOFR Function 
    const [sofrFunction, setSofrFunction] = useState(() => getGeneratorDevaluationFunctionByDay(con.SOFR_DAYS.map(daysIdToInt), currentSofr))

    useEffect(() => {
        setSofrFunction(() => getGeneratorDevaluationFunctionByDay(con.SOFR_DAYS.map(daysIdToInt), currentSofr))
    }, [currentSofr])
    

    // IBR Function
    const [ibrFunction, setIbrFunction] = useState(() => getGeneratorDevaluationFunctionByDay(con.IBR_DAYS.map(daysIdToInt), currentIBR))

    useEffect(() => {    
        setIbrFunction(() => getGeneratorDevaluationFunctionByDay(con.IBR_DAYS.map(daysIdToInt), currentIBR))
    }, [currentIBR])


    // Assessed Options Coverages
    const [assessedOptionsCoverages, setAssessedOptionsCoverages] = useState(() => arrayToObjectByAttribute(Object.values(options).map((op) => computeOptionAssessmentColumns(op, referenceDate, currentOptionRate, hist_volatility, sofrFunction, ibrFunction, volatilitySurface))))

    // Assessment Summary
    const [assessmentSummary, setAssessmentSummary] = useState(() => {return({[con.ACTIVE_OPTION_AMOUNT] : 0,
                                                                                [con.ASSESSMENT_RATE] : 0,
                                                                                [con.PREMIUM_GAINS] : 0,
                                                                                [con.PORTFOLIO_ASSESSMENT] : 0,
                                                                                [con.TOTAL_ASSESSMENT] : 0})})


    // Updates Assessed Coverages
    useEffect(() => {

        let assessedArray = Object.values(options).map((op) => computeOptionAssessmentColumns(op, referenceDate, currentOptionRate, hist_volatility, sofrFunction, ibrFunction, volatilitySurface))

        // Sets Object
        setAssessedOptionsCoverages(arrayToObjectByAttribute(assessedArray))

        // Sets Summary
        setAssessmentSummary(computeOptionAssessmentSummary(assessedArray, option_assessment_filter_function, currentOptionRate))


    }, [options, referenceDate, currentOptionRate, hist_volatility, sofrFunction, ibrFunction, volatilitySurface, option_assessment_filter_function])



    return([assessedOptionsCoverages, assessmentSummary])
}


