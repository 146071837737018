// TODO - Documentar

import React, { useEffect, useState } from 'react'
import * as con from "../../GlobalConstants"


import { RefApplicationParameterHook, RefNestedApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { IsStaff } from '../hooks/AuthenticationHook'
import { Button, Checkbox } from '@blueprintjs/core'
import { downloadExcelFromArray } from '../../utils/downloadFunctions'
import { allForwardCoverageColumns, prepareForExport } from '../../utils/transactionFunctions'
import Modal from '../../templates/popups/Modal'
import { createNotification } from '../../templates/notifications/Notifications'
import { translateTransactionColumnName } from '../../utils/translateFunctions'
import { filterObject } from '../../GlobalFunctions'
import { constructFileName, makeForwardAssessmentReport } from '../../utils/reportFunctions'
import LoadingIcon from '../../templates/loading/LoadingIcon'
import LoadingIconSmall from '../../templates/loading/LoadingIconSmall'


// Download Columns
const fixedDownloadColumns = [{ [con.ID] : con.OPENING_DATE},
                            { [con.ID] : con.EXPIRATION_DATE},
                            { [con.ID] : con.AMOUNT},                          
                            { [con.ID] : con.RATE},                                                 
                            { [con.ID] : con.ASSESSMENT},
                            { [con.ID] : con.COUNTERPARTY},
                            { [con.ID] : con.NEW_FORWARD_RATE}]    

const optionalDownloadColumns = [{ [con.ID] : con.OPENING_SPOT},                          
                                { [con.ID] : con.EXPIRY_DAYS},                                                                              
                                { [con.ID] : con.NEW_FORWARD_RATE},
                                { [con.ID] : con.FORWARD_RATE_DIFFERENCE_PRESENT_VALUE},                        
                                { [con.ID] : con.COVERAGE_TYPE}]

const staffDownloadColumns = [{ [con.ID] : con.DEVALUATION_VALUE},
                              { [con.ID] : con.FORWARD_RATE_DIFFERENCE},
                              { [con.ID] : con.DISCOUNT_RATE}]     


function ForwardAssessmentDownload({assessmentSummary, assessedFwdDictionary}) {


    // Hidden columns
    const isStaff = IsStaff()

    // Fwd Assessment Filter Function 
    const forwardFilterFun = RefApplicationParameterHook(con.FWD_ASSESSMENT_FILTER_FUN)

    // Forward coverages loaded
    const forwardCoveragesLoadingStatus = RefNestedApplicationParameterHook(con.TRANSACTIONS_LOADED, con.COVERAGES_FWD)

    // Download Dialog
    const [showDownloadDialog, setShowDownloadDialog] = useState(() => false)

    // Const included Columns
    const [includedColumns, setIncludedColumns] = useState(() => optionalDownloadColumns.map(col => {return(  {...col, [con.SELECTED] : false})})) 

    // Generating Report
    const generatingReportStatus = RefNestedApplicationParameterHook(con.FORWARD_ASSESSMENT_REPORT, con.GENERATING)

    // Has Coverages
    const [hasCoverages, setHasCoverages] = useState(() => true)

    useEffect(() => {
      
      setHasCoverages(Object.values(assessedFwdDictionary).filter((fwd) => forwardFilterFun(fwd)).length > 0)

    }, [assessedFwdDictionary, forwardFilterFun])
    


    const toggleSelectedColumn = (idCol) =>
    {
      setIncludedColumns(includedColumns.map(col => {return({...col, [con.SELECTED] : col[con.ID] === idCol ? !col[con.SELECTED] : col[con.SELECTED]})}))
    }

    const downloadForwardAssessment = () =>
    {

      let columns = fixedDownloadColumns.concat(includedColumns.filter(col => col[con.SELECTED]))

      if(isStaff)
        columns = columns.concat(staffDownloadColumns)

      // Adds all the info
      columns = columns.map(col => translateTransactionColumnName(allForwardCoverageColumns[col[con.ID]]))

      // Filters
      let finalDict = filterObject(assessedFwdDictionary, (fwd) => forwardFilterFun(fwd))

      downloadExcelFromArray(prepareForExport(finalDict, columns), constructFileName(con.FORWARD_ASSESSMENT_SHEET_NAME, con.XLSX_FILE_TYPE))
      
    }
    

  return (
    <div>

          <Modal show={showDownloadDialog} doAccept={null} showCancel={false} doCancel={() => setShowDownloadDialog(false)}>      
           
                    <h2>Descargar Valoración</h2>

                    <h3>Columnas Extras a Incluir</h3>
                    <div className='columnParameterBoxLeft'>
                        {
                          includedColumns.map(col => 
                            <Checkbox key={col[con.ID]} checked={col[con.SELECTED]} label={ translateTransactionColumnName(allForwardCoverageColumns[col[con.ID]])[con.NAME]} onChange={() => toggleSelectedColumn(col[con.ID])}/>
                            )
                        }
                    </div>
                  
                    <div>
                      <Button icon="download" text="Descargar" onClick={() => {downloadForwardAssessment()
                                                                              createNotification("Valoración descargada") 
                                                                              setShowDownloadDialog(false)}} />
                    </div>
                                                  
            </Modal>   
    
            <div style={{marginTop : "10px"}}>
              {
                forwardCoveragesLoadingStatus[con.STATUS] === con.OK 
                ? <div>
                    <div>
                      <Button icon="download" text="Descargar Excel" disabled={!hasCoverages} onClick={() => setShowDownloadDialog(true)}/> 
                    </div>
                    <div style={{marginTop : "10px"}}>
                    { generatingReportStatus 
                      ? <div className='smallParameterBox'><LoadingIconSmall /></div>
                      : <Button text="Generar Reporte" disabled={!hasCoverages} onClick={() => makeForwardAssessmentReport(assessmentSummary, Object.values(assessedFwdDictionary).filter((fwd) => forwardFilterFun(fwd)))}/> 

                    }
                    </div>
                 </div>
                : <LoadingIcon />
              }
              
            </div>  
    </div>
  )

}

export default ForwardAssessmentDownload